<template>
  <div>
    <b-card
      class="overflow-hidden mt-3 mb-3 playlist"
      :class="{ selected : selectedId === data.music_playlist_id}"
      no-body
      @click="$emit('selected-playlist', data)">
      <b-row no-gutters>
        <b-col cols="3">
          <b-skeleton-wrapper :loading="thumbLoading">
            <template #loading>
              <b-skeleton-img class="thumb" aspect="1:1"></b-skeleton-img>
            </template>
            <img
              class="playlist-thumb"
              :src="data.thumbnail_url"
              alt="Playlist Photo"
              width="90"
              height="90"
            />
          </b-skeleton-wrapper>

          <img
            class="playlist-thumb hidden"
            :src="data.thumbnail_url"
            alt="Playlist Photo"
            width="90"
            height="90"
            @load="onThumbLoad"
          />
        </b-col>
        <b-col cols="9">
          <b-card-text class="playlist-name">
            {{ data.name }}
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  export default {
    props : {
      data : {
        type     : Object,
        required : true,
      },
      selectedId : {
        type : Number,
      },
    },
    data() {
      return {
        thumbLoading : true,
      }
    },
    methods : {

      /**
       * On Thumbnail Load
       */
      onThumbLoad() {
        this.thumbLoading = false;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .playlist {
    &:hover {
      background-color: #F2F2F2;
      cursor: pointer;
    }
  }

  .selected {
    background-color: #e2e0e0;
  }

  .playlist-name {
    padding: 1rem 0.5rem;
    font-size: 0.8em !important;
    font-weight: bold;
  }

  .playlist-thumb {
    border-right: 1px solid #ddd;
  }

  .hidden {
    visibility: hidden;
    position: fixed; //absolute;
    display: inline-block;
  }
</style>